import { As, ListItem } from '@chakra-ui/react';
import { NavPost } from '../../../types';
import Link from '../../Link';
import { mobileNavBreakpointKey } from '../utils';

const NavLinkItem = ({
  url,
  title,
  as,
  grandChild,
  onNavigate,
}: NavPost & { as?: As; grandChild?: boolean; onNavigate: () => void }) => (
  <ListItem
    as={as}
    px={grandChild ? 4 : { base: 4, [mobileNavBreakpointKey]: 0 }}
  >
    <Link
      href={url}
      textStyle={grandChild ? 'mainNav.linkItem' : 'mainNav.navItem'}
      display="block"
      onClick={onNavigate}
      {...(grandChild
        ? null
        : {
            py: 2,
          })}
    >
      {title}
    </Link>
  </ListItem>
);

export default NavLinkItem;
