import {
  List,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Accordion,
  As,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { NavPost } from '../../../types';
import { arrayShift } from '../utils';
import LinkItem from './LinkItem';

const ControlledFragment: FC = ({ children }) => <>{children}</>;

const MobileMenuItem = ({
  title,
  children,
  ID,
  as: itemAs,
  child,
  onNavigate,
  defaultIndexes,
}: NavPost & {
  as: As;
  child?: boolean;
  onNavigate: () => void;
  defaultIndexes?: number[];
}) => {
  const isList = children && children.length > 1;
  const ItemTitle = child ? 'h3' : 'h2';
  const hasGrandChildren = isList && !!children[0].children?.length;
  let resolvedAccordionIndex = -1;

  const isFinalLevelChildren = !children?.some(({ children: c }) => c);
  return (
    <AccordionItem as={itemAs} border="none">
      <ItemTitle>
        <Text as={AccordionButton} textStyle="mainNav.navItem">
          {title}
          <AccordionIcon m="0" />
        </Text>
      </ItemTitle>
      <AccordionPanel
        as={isList ? List : 'div'}
        id={`main-menu-sub-nav-${ID}`}
        bg="secondaryBackgroundColor"
        pb="1px"
        borderTop={child ? 'none' : '4px solid'}
        borderTopColor="highlightColor"
        {...(isList
          ? {
              variant: 'loose',
              pt: !hasGrandChildren ? 4 : undefined,
            }
          : null)}
      >
        <Accordion
          allowToggle
          as={ControlledFragment}
          variant="nav"
          defaultIndex={defaultIndexes ? defaultIndexes[0] : undefined}
        >
          {children?.map((item) => {
            if (item.children || isFinalLevelChildren) {
              resolvedAccordionIndex += 1;
            }

            const isSelected =
              defaultIndexes && defaultIndexes[0] === resolvedAccordionIndex;

            return item.children ? (
              <MobileMenuItem
                {...item}
                key={item.ID}
                as={isList ? itemAs : 'div'}
                onNavigate={onNavigate}
                defaultIndexes={
                  isSelected ? arrayShift(defaultIndexes) : undefined
                }
                child
              />
            ) : (
              <LinkItem
                {...item}
                as={isList ? itemAs : 'div'}
                key={item.ID}
                onNavigate={onNavigate}
                grandChild
              />
            );
          })}
        </Accordion>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default MobileMenuItem;
